// export const serverUrl = "https://ecg-base.cardiomood.com"; // prod
export const serverUrl = "https://test-base.ecg.corsano.com"; // test-prod
// export const serverUrl = "http://localhost:8000"; // local test 

export const folderListUri = "/api/folder/list/get/";
export const recordListUri = "/api/record/list/get/";
export const folderStatUri = "/api/stat/table/get/";
export const recordUri = "/api/record/get/";
export const annotationUpdateUri = "/api/record/annotation/update/";
export const startProcessRecordUri = "/api/record/processing/start/";
export const C_DEFAULT_RECORD_SOURCE_REF = "https://api.prod.doctors.cardiomood.com/v2/szobin/"

// export const defaultRecordId = "test_record";  // "rr5_proc";  "test_proc_afib_1r";  "test_dc";
// export const defaultRecordId = "test_record_2";

export const C_DEFAULT_TIME_LENGTH = 10;  // minutes
